
import {computed, defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {ISearchField} from "@/core/entity/ISearchField";
import {CASE_STATUS, CASE_TYPE, OPERATOR, SEARCH_ENTITY} from "@/core/config/Constant";
import {ICase} from "@/core/entity/ICase";
import {
  CaseManagerSelect,
  CompanySelect,
  HandlePanel,
  LoadPanel,
  SearchEntityEvent,
  UserSelect
} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import BaseSelect from "../../../components/base/select/BaseSelect.vue";
import DateTimeFormat from "../../../components/base/datetime/DateTimeFormat.vue";
import KtDatatable from "../../../components/kt-datatable/KTDatatable.vue";
import Entities from "../../../components/base/common/Entities.vue";

export default defineComponent({
  name: "Inpatients",
  components: {BaseSelect, DateTimeFormat,    KtDatatable,  Entities},
  setup() {
    const store = useStore();
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Inpatient Case Management',
        [
          {link: false, router: '', text: 'Inpatient Case Management'}
        ]
      )
    })
    const fields = ['all']
    const fieldSearch: ISearchField[] = [
      {column: 'clientId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Client'},
      {column: 'caseType', type: 'HIDDEN', operator: OPERATOR.EQ, value: 'In-patient', name: 'Type'},
      {column: 'cmStatus', type: 'HIDDEN', operator: OPERATOR.EQ, value: 'ACTIVE', name: 'Status'},
      {column: 'managerId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Manager'},
    ]
    const tableHeader = ref([
      {
        name: "Case#",
        key: "caseNumber",
        sortable: true,
      },
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Policy#",
        key: "policyNumber",
        sortable: true,
      },
      {
        name: "insured",
        key: "insured",
        sortable: true,
      },
      {
        name: "DOB",
        key: "dob",
        sortable: true,
      },
      {
        name: "Case Type",
        key: "caseType",
        sortable: true,
      },
      {
        name: "Case Status",
        key: "caseStatus",
        sortable: true,
      },

      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const caseViewPage = computed(() => store.state.CaseModule.caseViewPage);
    const caze = computed<ICase>(() => store.state.CaseModule.case);
    return {
      tableHeader,
      ...SearchEntityEvent(Actions.CASE_SEARCH, fieldSearch, fields, [], SEARCH_ENTITY.CASE_VIEW),
      caseViewPage,
      ...HandlePanel(Actions.LOAD_CASE),
      caze,
      ...CompanySelect(),
      ...LoadPanel(),
      CASE_STATUS,
      CASE_TYPE,
      ...UserSelect(),
      ...CaseManagerSelect(),
    }
  }
})
